import React from 'react';
import PropTypes from 'prop-types';
import { StyledModal, StyledBody, StyledHeader, Container, StyledInputNewP1, InputContainer, SubmitContainer, FieldTitle, InputContainerr, StyledInputContainer, PopupHeaderNew } from './styles';
import { UserButtonUpdate } from '../EngagementSection/styles';
import { BoldV2, StyledModalV2 } from './styles';
import { StyledBodyV2, SubmitContainerV2 } from '../AddWellnessInitiative/styles';
import { UserButtonV2 } from '../EngagementSection/styles';
import { DeleteFiledIcon } from '../../utils/icons';


class AddLocationPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          city: '',
          state: '',
          id: ''
        }
      ],
      deleted: [],
      showDeleteLocationPopUp: false,
      currentId: null,
      currentLocationCity: null,
      currentLocationState: null

    }
  }

  componentDidMount() {
    const { data } = this.props;
    if (data && Array.isArray(data)) {
      const loc = data.filter((obj) => obj.id !== null);
      this.setState({
        data: loc
      });
    }
  }

  onChangeInput = (e, index) => {
    const duplicateData = this.state.data;
    duplicateData[index][e.target.name] = e.target.value;
    duplicateData[index]["change"] = true;
    this.setState({
      data: duplicateData,
    })
  }

  onToggleDeleteLocationPopup = (details) => {
    this.setState({ showDeleteLocationPopUp: !this.state.showDeleteLocationPopUp, currentId: details.id, currentLocationCity: details.city, currentLocationState: details.state });
  };

  showInputContainer = () => (
    (<Container >
      <FieldTitle style={{ right: '45px',color:'#005C87' }} paddingTop={1}>All Locations</FieldTitle>
      {this.state.data.map((details, index) => (
        <InputContainer key={index} marginTop={1} marginBottom={1}>
          <StyledInputContainer>
            <StyledInputNewP1 width='90%' type="text" placeholder='Enter City, State, Country' name='location' value={details?.location}
              onChange={(e) => this.onChangeInput(e, index)} /> 
          </StyledInputContainer>
          <div style={{ cursor: 'pointer' }} onClick={() => this.onToggleDeleteLocationPopup(details, index)} className='delete' >{DeleteFiledIcon()}</div>
        </InputContainer>
      ))}
    </Container>)
  )

  handleDelete = () => {
    const newData = this.state.data.filter((obj) => obj.id != this.state.currentId)
    let deletearray = this.state.deleted;
    this.state.currentId && deletearray.push(this.state.currentId)
    this.setState({ data: newData, showDeleteLocationPopUp: !this.state.showDeleteLocationPopUp, deleted: deletearray })
  }


  deleteLocationPopup = () => (
    (<StyledModalV2
      show={this.state.showDeleteLocationPopUp ? true : false}
      onHide={this.onToggleDeleteLocationPopup}
      dialogClassName={'delete-popup'}
      backdropClassName='drop-back'
    >
      <StyledBodyV2>
        <BoldV2>
          Are you sure you want to <span className='name-in-popup'>Delete<br />
            this Location ({this.state.currentLocationCity},{this.state.currentLocationState})?</span>
          <div className="subContent">
            Once you delete the Location, then it<br />
            will be removed for all users in Woliba.<br />
            Also, users with this location would<br />
            no longer have a location so you’d<br />
            need to update those users.
          </div>
        </BoldV2>
        <SubmitContainerV2>
          <UserButtonV2
            radius="5px"
            bgColor="#FD7175"
            width="125px"
            height="50px"
            fontSize="18px"
            Align={1}
            float={1}
            marginLeft="0px"
            onClick={() => this.handleDelete()}
            font="Rubik"
            capitalize="capitalize"
            marginRight="15px"
          >
            {"Delete"}
          </UserButtonV2>
          <UserButtonV2
            radius="5px"
            bgColor="none"
            width="125px"
            height="50px"
            fontSize="18px"
            Align={1}
            float={1}
            marginLeft="0px"
            onClick={this.onToggleDeleteLocationPopup}
            outline={0}
            font="Rubik"
            capitalize="capitalize"
            textColor="#9C9C9C"
            border="border: 2px solid #9C9C9C"
            boxSizing="border-box"
          >
            cancel
          </UserButtonV2>
        </SubmitContainerV2>
      </StyledBodyV2>
    </StyledModalV2>
    )
  )


  addEmployeeInputContainer = () => {
    const duplicateData = this.state.data;
    const obj = {
      city: '',
    }
    duplicateData.push(obj);
    this.setState({
      data: duplicateData
    })
  }

  addLocationButton = () => {
    const data = this.state.data.filter((obj) => obj.change === true);
    data.map((item) => {
      delete item.change
    })
    let newarray = [];
    data.map((obj) => {
      let someString = obj.location;
      let newindex = someString.indexOf(",");
    
      let city = someString;
      let state = '';
    
      if (newindex !== -1) {
        city = someString.substr(0, newindex).trim();
        state = someString.substr(newindex + 1).trim();
      }
    
      let location = { "city": city, "state": state };
      
      if (obj.id) {
        location["id"] = obj.id;
      }
    
      newarray.push(location);
    });
    this.props.showModalPopUp()
    this.props.fetchEditLocationApi({ "locations": newarray, "company_id": this.props.companyId }, { "delete_location": this.state.deleted, "delete_department": [] })

  };

  render() {
    const { show } = this.props;
    const { fileName, csvUpload } = this.state;
    return (
      <StyledModal show={show} onHide={this.props.showModalPopUp}>
        <StyledHeader closeButton>
          <PopupHeaderNew >
            <div className='headingTitle'>
              Add / Edit Location
            </div>
            <div className='closeButton'>
              <img src="/public/images/new_close_icon.png" alt="" style={{ "cursor": "pointer",height:"20px", width:"20px" }} onClick={this.props.showModalPopUp} />
            </div>
          </PopupHeaderNew>
        </StyledHeader>
        <StyledBody>
          {this.showInputContainer()}
          <Container maxWidth={"100%"}>
            <InputContainerr Align={1}>
              <div className="addMoreButton" onClick={this.addEmployeeInputContainer}>
                Add More
              </div>
            </InputContainerr>
            {
              csvUpload &&
                <InputContainer Align={1}>      
                  <div className="SuccessUpload">
                    <span className="glyphicon glyphicon-trash" onClick={this.deleteCSV} />
                    {fileName}
                  </div>
                </InputContainer>
            }
            <hr className='divider'></hr>
          </Container>
          <SubmitContainer padding={1}>
            <UserButtonUpdate bgColor="#007AB1" width="550px" padding="0px" height="50px" Align={1} fontSize="12px" onClick={() => {this.addLocationButton()
              this.props.showModalPopUp}}>
              <div className="send">
                Save The Updates
              </div>
            </UserButtonUpdate>
          </SubmitContainer>
        </StyledBody>
        {this.deleteLocationPopup()}
      </StyledModal>
    );
  }
}

AddLocationPopup.propTypes = {
  show: PropTypes.bool,
  showModalPopUp: PropTypes.func,
  postAddEmployee: PropTypes.func,
  companyId: PropTypes.number,
  companyAuthDetails: PropTypes.func,
  fetchEditLocationApi: PropTypes.func,
  data: PropTypes.array,
}

export default AddLocationPopup;